<template>
  <div>
    <title>SIM INVENTORY ~ ADD DATA RECEIPT OF ITEM DETAILS</title>
    <section class="content-header">
      <h1>
        Add Data Receipt of Items Details
        <br />
        <h4>
          Please enter the transaction data for the detailed item receipt list
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Items Receipt List Transaction details</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-save"></i>
              <h3 class="box-title">Save Item Receipt List Details</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <div class="box-body">
              <div class="row">
                <div class="col-md-6">
                  <label for="exampleInputEmail1">Register Number / BC Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.nobc"
                    autocomplete="off"
                    class="form-control"
                  />
                  <br />
                </div>
                <div class="col-md-6">
                  <label for="exampleInputEmail1">Receipt Number </label>
                  <input
                    type="text"
                    readonly
                    v-model="isidata.receiptno"
                    autocomplete="off"
                    class="form-control"
                  />
                  <br />
                </div>
              </div>
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Item Code</th>
                      <th>Description</th>
                      <th>Unit Price</th>
                      <th>CIF</th>
                      <th>CIF Rupiah</th>
                      <th>Invoice Price</th>
                      <th>Submission Price</th>
                      <th>Unit Price</th>
                      <th>FOB</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>Notes</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 }}</td>
                      <!-- <td>{{ classdata.idx }}</td> -->
                      <td>
                        {{ classdata.KODE_BARANG }}
                      </td>
                      <td>
                        {{ classdata.URAIAN }}
                      </td>
                      <td>
                        {{ classdata.HARGA_SATUAN }}
                      </td>
                      <td>
                        {{ classdata.CIF }}
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.CIF"
                        />
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.ID"
                        />
                      </td>
                      <td>
                        {{ classdata.CIF_RUPIAH }}
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.CIF_RUPIAH"
                        />
                      </td>
                      <td>
                        {{ classdata.HARGA_INVOICE }}
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.HARGA_INVOICE"
                        />
                      </td>
                      <td>
                        {{ classdata.HARGA_PENYERAHAN }}
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.HARGA_PENYERAHAN"
                        />
                      </td>
                      <td>
                        {{ classdata.HARGA_SATUAN }}
                        <input
                          type="hidden"
                          class="form-control"
                          v-model="classdata.HARGA_SATUAN"
                        />
                      </td>
                      <td>
                        {{ classdata.FOB }}
                      </td>
                      <td>
                        <input
                          type="text"
                          class="form-control"
                          v-model="classdata.JUMLAH_SATUAN"
                          name="qtydeta"
                          id="qtydetil"
                        />
                      </td>
                      <td>
                        {{ classdata.KODE_SATUAN }}
                      </td>
                      <td>
                        <input type="text" class="form-control" v-model="classdata.notesd" name="catatan" id="catatan">
                      </td>
                      <td>
                        <button
                          @click="saveData(classdata)"
                          type="button"
                          class="btn btn-primary"
                        >
                          <i class="fa fa-save"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      list_paged: [],
      loading: false,
      isidata: {
        nobc: "",
        receiptno: "",
        unitd: ""
      },
      classdata: {
        CIF: "",
        CIF_RUPIAH: "",
        KODE_BARANG: "",
        HARGA_INVOICE: "",
        HARGA_PENYERAHAN: "",
        HARGA_SATUAN: "",
        JUMLAH_SATUAN: "",
        KODE_SATUAN: "",
        ID: "",
        notesd: "",
      }
    };
  },
  created() {
    // console.log(this.$route.params.id);
    this.isidata.nobc = this.$route.params.nobc;
    this.isidata.receiptno = sessionStorage.getItem('receiptnumb')
    this.getidheadertpbbarang();
  },
  methods: {
    getbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/barang/getbarangbykode_barang?kode_barang=" + this.isidata.kodebarang;
      const urlAPIget =
        this.$apiurl +
        "barang/getbarangbykode_barang?kode_barang=" +
        this.isidata.kodebarang;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, Item Code not found",
              showConfirmButton: false
            });
          } else {
            this.isidata.kodebarang = resp.data.data.kode_barang;
            this.isidata.namabarang = resp.data.data.nama_barang;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getidheadertpbbarang() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/tpb/getalldatatpb_barang?length=1000&id_header=" + this.$route.params.id;

      const urlAPIget =
        this.$apiurl +
        "tpb/getalldatatpb_barang?length=1000&id_header=" +
        this.$route.params.id;
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          // console.log(resp);
          this.list_paged = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    saveData: function(classdata) {
      this.loading = true;
      var kodeuser = sessionStorage.getItem("kodeuser");
      const paramdatadetail = {
        receipt_number: this.isidata.receiptno,
        kode_barang: classdata.KODE_BARANG,
        notes: classdata.notesd,
        qty: classdata.JUMLAH_SATUAN,
        kode_user: kodeuser,
        unit: classdata.KODE_SATUAN,
        cif: classdata.CIF,
        cif_rupiah: classdata.CIF_RUPIAH,
        harga_invoice: classdata.HARGA_INVOICE,
        harga_penyerahan: classdata.HARGA_PENYERAHAN,
        harga_satuan: classdata.HARGA_SATUAN,
        id_tpb_barang: classdata.ID
      };
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };

      const urlAPISaveDatadetail =
        this.$apiurl + "penerimaan_d/savepenerimaan_d";
      axios
        .post(urlAPISaveDatadetail, paramdatadetail, { headers })
        .then((respn) => {
          if (respn.data.status == true) {
            swal({
              icon: "success",
              title: "Success",
              text: "Save data successfully",
              showConfirmButton: false
            });
            this.loading = false;
            sessionStorage.removeItem("receiptnumb");
            // this.$router.push({ name: "penerimaanheader" });
          } else {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Save data failed",
              showConfirmButton: false
            });
            this.loading = false;
            // this.$router.push({ name: "penerimaanheader" });
          }
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
            showConfirmButton: false
          });
          console.log(err);
          this.loading = false;
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
